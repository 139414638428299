import React, { useState, useRef, useEffect } from 'react'
import { useDebouncedEffect } from '@/utils/useDebouncedEffect'
import useQuery from '@/hooks/useQuery'
import Loading from '@/shared/Loading'

function resolveLink (result) {
  if (result.kind === 'User') {
    window.location.href = `/${result.object.username}`
  }
  if (result.kind === 'Credit') {
    window.location.href = `/credits/${result.object.slug}`
  }
  if (result.kind === 'Project') {
    window.location.href = `/projects/${result.object.slug}`
  }
}

const ResultRow = ({ result, selected }) => {
  const { object } = result

  const selectedClasses = selected ? 'bg-cccblue bg-opacity-5 dark:bg-gray-900 dark:bg-opacity-100 text-cccblue' : 'dark:bg-gray-800'
  const classes = `flex items-center justify-between p-4 ${selectedClasses}`

  const gotoResult = () => { resolveLink(result) }

  if (result.kind === 'User') {
    return <li onClick={gotoResult} className={classes} role="option" tabIndex="-1">
      <div className='flex flex-col truncate'>
        <span className="whitespace-nowrap font-semibold truncate">{object.displayName}</span>
        <span className="text-sm">@{object.username}</span>
      </div>
      <span className="ml-4 text-right text-xs text-slate-600">Member</span>
    </li>
  }
  if (result.kind === 'Credit') {
    return <li onClick={gotoResult} className={classes} role="option" tabIndex="-1">
      <div className='flex flex-col truncate'>
        <span className="whitespace-nowrap font-semibold truncate">{object.name}</span>
        <span className="text-sm truncate">{object.year} {object.parentName}</span>
      </div>
      <span className="ml-4 text-right text-xs text-slate-600">Production Credit</span>
    </li>
  }
  if (result.kind === 'Project') {
    return <li onClick={gotoResult} className={classes} role="option" tabIndex="-1">
      <span className="whitespace-nowrap font-semibold truncate">{object.name}</span>
      <span className="ml-4 text-right text-xs text-slate-600">Open Project</span>
    </li>
  }
}

const Search = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState([])
  const [contains, setContains] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(null)
  const { getRequest } = useQuery()
  const searchRef = useRef(null)

  const handleOutsideClick = e => {
    if (!isOpen) { return }

    if (searchRef.current && !searchRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useDebouncedEffect(() => { fetchData() }, 200, [contains])

  const fetchData = () => {
    if (contains.length === 0) {
      setLoading(false)
      setResults([])
      return
    }

    setLoading(true)
    getRequest('/api/v3/omnisearch/search', { search: contains }, (err, jsonData) => {
      setLoading(false)
      if (err) { /* hooks */ return }

      setResults(jsonData.results)
      setSelectedIndex(null)
    })
  }

  const updateSelectedIndex = (e) => {
    if (e.keyCode === 38) { // up
      if (selectedIndex === null) { return }
      if (selectedIndex <= 0) { setSelectedIndex(null) }

      setSelectedIndex(selectedIndex - 1)
    }
    if (e.keyCode === 40) { // down
      if (selectedIndex === null) { setSelectedIndex(0) }
      if (selectedIndex >= results.length) { return }

      setSelectedIndex(selectedIndex + 1)
    }
    if (e.keyCode === 13) { // enter
      if (selectedIndex === null) { return }

      const result = results[selectedIndex]
      resolveLink(result)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  })

  return (
    <div className='flex-1'>
      <div className="mx-auto w-full max-w-xs lg:max-w-md" onClick={() => setIsOpen(true)}>
        <label htmlFor="search" className="sr-only">Search</label>
        <div className="relative text-white text-cccblue">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
            </svg>
          </div>
          <div id="search" className="block w-full rounded-md border border-2 border-opacity-30 border-cccblue bg-cccblue bg-opacity-5 py-2 pl-10 pr-3 text-gray-400 dark:text-gray-700 sm:text-sm cursor-text" placeholder="Search">Search</div>
        </div>
      </div>
      { isOpen && <>
        <div className="fixed inset-0 z-50 flex items-start justify-center pt-16 sm:pt-24" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gradient-to-r from-cccpurple via-cccblue to-cccorange backdrop-blur transition-opacity opacity-70"></div>
          <div className="relative w-full max-w-lg transform px-4 transition-all opacity-100 scale-100">
            <div className="overflow-hidden rounded-lg bg-white shadow-md" ref={searchRef}>
              <div className="relative shadow-sm p-1 dark:bg-gray-900">
                <input autoFocus type='text' className="block w-full appearance-none bg-transparent py-4 pl-4 pr-12 text-base text-gray-900 placeholder:text-gray-900 focus:outline-none sm:text-sm sm:leading-6" placeholder="Search Members, Credits, or Projects by name..." aria-label="Search components" role="combobox" aria-expanded="true" onChange={(e) => setContains(e.target.value)} onKeyDown={updateSelectedIndex} />
                <svg className="pointer-events-none absolute top-4 right-4 h-6 w-6" xmlns="http://www.w3.org/2000/svg">
                  <path fill='currentColor' d="M20.47 21.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06Zm-9.97-4.28a6.75 6.75 0 0 1-6.75-6.75h-1.5a8.25 8.25 0 0 0 8.25 8.25v-1.5ZM3.75 10.5a6.75 6.75 0 0 1 6.75-6.75v-1.5a8.25 8.25 0 0 0-8.25 8.25h1.5Zm6.75-6.75a6.75 6.75 0 0 1 6.75 6.75h1.5a8.25 8.25 0 0 0-8.25-8.25v1.5Zm11.03 16.72-5.196-5.197-1.061 1.06 5.197 5.197 1.06-1.06Zm-4.28-9.97c0 1.864-.755 3.55-1.977 4.773l1.06 1.06A8.226 8.226 0 0 0 18.75 10.5h-1.5Zm-1.977 4.773A6.727 6.727 0 0 1 10.5 17.25v1.5a8.226 8.226 0 0 0 5.834-2.416l-1.061-1.061Z"></path>
                </svg>
              </div>
              <ul className="max-h-[18.375rem] divide-y divide-slate-200 overflow-y-auto rounded-b-lg text-sm leading-6" role="listbox" id="headlessui-combobox-options-155">
                { results.map((result, idx) => <ResultRow key={result.key} result={result} selected={idx === selectedIndex} />)}
              </ul>
              { loading && <Loading /> }
            </div>
          </div>
        </div>
      </> }
    </div>
  )
}

export default Search

Search.propTypes = {
}
